.patientOverview {

  max-width: 767px;
  margin: 0 auto;

  .assessmentParameter .donutChart:after {
    background-color: white;
  }

  section {
    margin: 0px auto 50px;
    em {
      line-height: 18px;
      display: block;
      margin-top: 10px;
      @media (max-width: 550px) {
        font-size: 14px;
      }  
    }
    .sectionHeader {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ebebeb;
      padding: 10px 10px 10px 15px;
      h2 {
        text-align: left;
        flex: 1;
        div {
          display: inline-block;
        }
        span {
          min-width: 130px;
          font-size: 12px;
          color: #888;
          display: block;
          margin-left: 20px;
          margin-top: -8px;
        }
      }
      .icon {
        width: 20px;
        height: 20px;
        position: relative;
        top: 4px;
        transition: .15s;
        cursor: pointer;
        left: -10px;
        transform: scale(1);
        &:hover {
          transform: scale(1.25);
        }
      }

    }
    h3 {
      margin: 0;
    }
    h2 {
      font-size: 18px;
      margin: 0;
    }
    
  }

  .rideSummary {
    border-top: 0px;
    margin-left: -40px;
  }
  .rideContent {
    display: flex;
    padding-top: 5px;
    @media (max-width: 590px) {
      flex-direction: column;
    }
    .scoreItem {
      margin: 5px 0;
      h3 {
        text-align: right;
      }
    }
  }

  .lastAssessment {
    .assessment {
      max-width: 100%;
      margin: 0;
    }
  }

  .coreScoreWrapper {
    flex: 1;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-start;
    .score {
      justify-content: flex-end;
      margin-bottom: -20px;
    }
    @media (max-width: 768px) {
      padding-right: 20px;      
    }
    @media (max-width: 590px) {
      flex-direction: row;
      justify-content: space-around;
      padding-right: 0;
      .score {
        justify-content: center;
      }
      .scoreItem {
        margin: 0px 20px;
        h3 {
          text-align: center;
        }
      }
    }
    @media (max-width: 400px) {
      h3 {
        font-size: 12px;
      }
    }
  }
  
  .lastNoteValue {
    margin: 20px auto 50px;
    background-color: white;
    padding: 20px;
    box-shadow: 3px 6px 10px 0px rgba(0,0,0,0.2);
    border-radius: 6px;
  }

  .questionnaireItem {
    max-width: 900px;

    .summary {
      text-align: center;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .summaryItem {
      display: flex !important;      
    }
    .questionnaireContent {
      padding: 10px;
      margin-top: 20px;
    }
  }

  .evaluationWrapper {
    transform: none !important;
    ol {
      flex: 1;
      margin: 10px 20px 10px 0px;
    }
    .summary {
      padding-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      width: 150px;
      @media (max-width: 768px) {
        padding-right: 20px;      
      }
      .angle {
        margin-bottom: 20px;
        &:after {
          right: -9px;
          top: -2px;      
        }
      }
      span {
        width: auto;
        height: 40px;
        line-height: 36px;
        text-align: right;
        font-weight: bold;
        font-size: 36px;
      }
    }
    li {
      position: relative;
      border-radius: 6px;
      padding: 0px 15px;
      margin: 10px;
      color: rgba(0,0,0,.8);
      .background {
        border-radius: 0px 6px 6px 6px;
        bottom: 0;
        left: 0;
        position: absolute;
        height: 5px;
        z-index: 1;
      }
      header {
        position: relative;
        z-index: 5;
        text-transform: capitalize;
        font-weight: bold;
        font-size: 18px;
        line-height: 40px;
        display: flex;
      }
      span {
        flex: 1;
        text-align: right;
      }
    }

    @media (max-width: 590px) {
      .flexRow {
        flex-direction: column;
        justify-content: center;  
      }
      .summary {
        flex-direction: row; 
        align-items: center;
        width: 100%;
        padding-right: 0;
        .angle {
          margin-bottom: 0;
          margin-right: 40px;
        }
        h3 {
          margin: 0 10px;
        }
      }
    }

  }


}

