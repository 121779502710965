@keyframes slideUp {
  from {
    transform: translateY(610px);
  }

  50% {
    transform: translateY(-10px);
  }

  to {
    transform: translateY(0px);
  }
}

.page.patientProfile {
  margin-top: 10px;
}

.patientProfileForm {

  @media (max-width: 500px) {
    footer.flexRow {
      flex-direction: row;
      margin-top: 15px;
    }
    .flexRow {
      flex-direction: column;
      .form-group {
        margin: 12px 20px;
        width: auto;
      }
    }
  }

  .preferences {
    max-width: 590px;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;    
    color: #777;
  }

  h2 {
    margin-bottom: 0;
  }
  .form-group {
    margin: 20px;
    width: 260px;
    input:disabled, select:disabled {
      color: black !important;
    }
    &.disabled > div:before {
      border-bottom: 0px !important;
    }
    &.disabled [role="button"] {
      color: black !important;
    }
  }

  footer {
    margin-top: 50px;
    animation: slideUp .4s;
    button {
      margin: 0px 25px;
    }
  }


}