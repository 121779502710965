.locationsList, .groupsList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.page.settingsLocations {
  max-width: 1024px;
  @media (max-width: 1024px) {
    padding-right: 0;
    padding-left: 0;
  }
}

.expandableItem.location {

  @media (max-width: 767px) {
    .flexRow {
      flex-direction: column;
    }
    footer.flexRow {
      flex-direction: row;
    }
  }
  .material-icons {
    font-size: 24px;
    margin-right: 5px;
    margin-left: 5px;
  }
  .caret {
    margin-left: 10px;
  }
  p {
    display: flex;
  }

  border-top: 1px solid #c8c8c8;
  @media (max-width: 1024px) {
    padding: 5px 20px;
  }

  [role="button"] {
    padding-left: 0px;
  }

  &:last-child {
    border-bottom: 1px solid #c8c8c8;
  }
  .flexRow {
    justify-content: flex-start;
  }
  .form-group {
    margin-bottom: 20px;
    margin-right: 40px;
    input:disabled {
      color: black;
    }
    &.disabled > div:before {
      border-bottom: 0px !important;
    }

  }
}