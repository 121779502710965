
@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.startRideAction {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(255,255,255,.7);
  align-items: center;
  bottom: 80px;
  .column {
    .button {
      width: 200px;
    }
  }
  .coreScore {
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: -10px;
    .star {
      width: 15px;
      height: 15px;
      margin-left: 10px;
    }
    .score {
      font-size: 24px;
      margin-left: 5px;
      font-weight: bold;
    }
  }
  .button {
    margin: 15px;
  }
  @media (min-width: 768px) {
    bottom: 101px;
    .button {
      margin: 25px;
    }
  }
}

.addSet {
  margin-top: 25px;
}

.rideSummary {
  border-top: 1px solid #e8e8e8;
}

.rideConfig, .rideSummary {
  padding-bottom: 90px;
  margin: 0 -20px;

  ol {
    margin: 0px;
    padding: 0;
    li {
      overflow: hidden;
      list-style-type: none;
      .set {
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        padding: 30px 15px;
        line-height: 40px;
        justify-content: space-around;
        align-items: center;
        font-size: 18px;
        text-transform: uppercase;
        animation: slideIn .5s;
        align-items: center;
        @media (max-width: 767px) {
          font-size: 14px;
          padding: 10px;
        }
      }

      input {
        font-size: 36px;
        line-height: 36px;
        width: auto;
        font-family: 'Cairo', sans-serif;
        text-align: center;
        display: inline-block;
        height: 52px;
        margin: 0;
        border: 0;
        width: 65px;
        @media (max-width: 1024px) {
          height: 50px;
          font-size: 24px;
        }
      }
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }
      .parameter {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        line-height: 20px;
        @media (max-width: 767px) {
          height: 70px;
        }
        @media (max-width: 590px) {
          flex-direction: column;
        }
        input[type=checkbox] {
          display: none;
        }
        label {
          margin: 0 10px;
        }
        .stepper {
          cursor: pointer;
          margin: 0 5px;
          user-select: none;
          -webkit-user-select: none;
          &.less {
            transform: rotate(180deg);
          }
        }
        &.parameter-direction {
          cursor: pointer;
          flex-direction: row;
          .direction-label {
            user-select: none;
            line-height: 0;
            transition: line-height .3s;
            :first-child {
              opacity: 0;
              transition: opacity .3s;
            }
            &.direction-ccw-label {
              line-height: 1;
              :first-child {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.delete {
  background-image: url('../img/x.svg');
  background-repeat: no-repeat;
  background-position: center center;
  height: 35px;
  width: 35px;
  border: 2px solid #c8c8c8;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  margin-left: 10px;
  flex-shrink: 0;
  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
  &.active {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }
}
.stepper {
  cursor: pointer;
  width: 35px;
  height: 35px;
  border: 2px solid #c8c8c8;
  border-radius: 100%;
  display: block;
  background-image: url('../img/caret.svg');
  background-size: 20px;
  background-position: left 50% center;
  background-repeat: no-repeat;
  /* Don't let a flex layout squeeze this element */
  flex-shrink: 0;
  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
    background-size: 15px;
  }
  @media (max-width: 590px) {
    display: none;
  }
  &.scss {
    transform: rotate(180deg)
  }
}

.modal.confirm {
  padding-bottom: 75px;

  .evaluationComplete {
    ol {
      li {
        align-items: center;
        font-size: 22px;
        height: 36px;
      }
    }
  }
  .coreScore {
    margin-bottom: 20px;
    margin-top: -50px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    justify-content: center;
    .icon {
      margin: 0 5px 0 10px;
      width: 20px;
      height: 20px;
    }
    .score {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .rideSummary {
    flex: 0;
    margin-bottom: 50px;
    li {
      padding: 10px 0px;
    }
  }

  footer.flexRow {
    position: fixed;
    bottom: 30px;
    left: 0;
    right: 0;
  }

  .newNote {
    width: 600px;
    @media (max-width: 650px) {
      width: 90vw;
      margin: 0;
      textarea {
        font-size: 12px;
      }
    }
    fieldset {
      border: 0px;
    }
    textarea {
      color: white;
    }
    background-color: rgba(0,0,0,0);
    margin: 25px auto;
  }

  .noteConfirm {
    pointer-events: none;
  }

  .questionnaireConfirm {
    .empty {
      opacity: .35;
    }
  }

  .assessment {
    margin-bottom: 50px;
    pointer-events: none;
  }

  .assessmentParameter {
    header {
      color: white;
    }
    input {
      color: white !important;
    }
    .donutChart {
      background-color: rgba(0,0,0,0) !important;
      svg path {
        stroke-width: 3px;
      }
      &:after {
        background-color: rgba(0,0,0,0);
      }
    }
  }

  ol {
    flex: 1;
    margin-top: 30px;
    li {
      font-size: 14px;
      &:first-child {
        strong {
          margin-top: 0px;
        }
      }
      strong {
        display: block;
        line-height: 16px;
        margin-top: 15px;
        margin-bottom: 5px;
      }
      span {
        display: block;
        line-height: 14px;
      }
    }
  }

}

.evaluationOverview {
  max-width: 500px;
  margin: 0px auto;
  line-height: 22px;
  padding: 0 5px 55px 5px;
  p {
    margin: 15px 0;
  }

  ol {
    padding-bottom: 50px;
    @media (max-width: 500px) {
      margin-right: 20px;
    }
    li {
      border-radius: 6px;
      background-color: #ebebeb;
      margin: 20px 10px;
      padding: 10px;
      position: relative;
      top: 0;
      transition: .2s;
      &.slideUp {
        top: -31px;
      }
      &.slideDown {
        top: 31px;
      }
    }
  }
  .arrowUp, .arrowDown {
    cursor: pointer;
    float: right;
    margin-right: -35px;
    width: 15px; 
    height: 15px; 
    display: none;
    background-image: url('../img/caret-green.svg');
    background-size: contain;
    background-repeat: no-repeat;
    &.active {
      display: block;
    }
  }

  .arrowUp {
    margin-top: -5px;
  }
  .arrowDown {
    margin-top: -10px;
    transform: rotate(180deg);
  }

  ul {
    li {

      margin: 25px 0;
      strong {
        display: block;
        text-transform: uppercase;
      }
    }
  }
}

.averageAngle {
  display: flex;
  flex-direction: column;
  margin: -4px 20px 0;
  @media (max-width: 500px) {
    width: 100%;
    .flexRow {
      justify-content: flex-start;
    }
  }
  label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    font-size: 28px;
    text-align: center;
    display: inline-block;
    height: 25px;
    border: 0;
    width: 55px;
    -moz-appearance: textfield;
  }
  .stepper {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 0;
    user-select: none;
    &.less {
      transform: rotate(180deg);
    }
  }

  input {
    font-size: 36px;
    line-height: 36px;
    width: auto;
    font-family: 'Cairo', sans-serif;
    text-align: center;
    display: inline-block;
    height: 52px;
    margin: 0;
    border: 0;
    width: 65px;
  }
}

.rideTemplateWrapper {
  padding-top: 25px;
  .swipeableViews {
    padding: 0 50px;
    @media (max-width: 500px) {
      padding: 0;
    }
  }
}

.rideTemplateSelect {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  max-width: 820px;
  padding-top: 15px;

  .rideTemplate {
    padding: 0 20px;
    cursor: pointer;
    @media (max-width: 500px) {
      padding: 0;
    }
  }

  .caretSelect {
    width: 20px;
    height: 40px;
    background-size: 75%;
    background-repeat: no-repeat;
    padding: 10px;
    cursor: pointer;
    background-position: center;
    background-image: url('../img/caret-thin.svg');
    opacity: .25;
    pointer-events: none;
    transform: scale(.85);
    transition: .3s;
    &.active {
      pointer-events: all;
      opacity: 1;
    }
    &.left {
      margin-right: 10px;
      &:hover {
        transform: scale(1);
      }
    }
    &.right {
      margin-left: 10px;
      transform: rotate(180deg) scale(.85);
      &:hover {
        transform: rotate(180deg) scale(1);
      }
    }
  }
  .rideContent {
    margin: 0 auto;
    border-radius: 6px;
    border: none;
    background-color: #ebebeb;
    border: 5px solid rgba(0,0,0,0);
    transition: .4s;
    @media (max-width: 500px) {
      .coreScoreWrapper {
        padding-top: 0px;
        h3 {
          font-size: 12px;
        }
        header {
          font-size: 16px;
          margin-bottom: -10px;  
        }
        .flexRow {
          .rideSummary {
            transform: scale(0.65);
          }
        }
      }
    }
    .coreScoreWrapper {
      animation: none;
    }
    &:hover {
      border: 5px solid rgb(119, 186, 62);      
    }
    header {
      font-size: 20px;
      padding-top: 15px;
    }
    .flexRow {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .rideSummary {
        padding: 10px 0;
        margin-bottom: 15px;
        border-top: 0px;
      }
    }
  }
}